// extracted by mini-css-extract-plugin
export var btnDefault = "main-module--btn-default--M7jOn";
export var attoiconJobs = "main-module--attoicon-jobs--moUTO";
export var attoiconNotes = "main-module--attoicon-notes--zwshT";
export var attoiconTimesheets = "main-module--attoicon-timesheets--HCcjI";
export var attoiconTimesheetsRounded1 = "main-module--attoicon-timesheets-rounded1--hnDBo";
export var attoiconMobileTt = "main-module--attoicon-mobile-tt--Kj8i+";
export var attoiconNamedSites = "main-module--attoicon-named-sites--qKpLs";
export var attoiconProfitableBids = "main-module--attoicon-profitable-bids--tE5Sd";
export var attoiconDepartments = "main-module--attoicon-departments--Z1iRk";
export var attoiconTimeline = "main-module--attoicon-timeline--Ux0Sx";
export var attoiconFilters = "main-module--attoicon-filters---PK2V";
export var attoiconVerified = "main-module--attoicon-verified--S62EK";
export var attoiconTimezone = "main-module--attoicon-timezone--ivDk+";
export var attoiconManualEntries = "main-module--attoicon-manual-entries---pxgV";
export var attoiconJobCosting = "main-module--attoicon-job-costing--3IMIY";
export var attoiconLocationTracking = "main-module--attoicon-location-tracking--looJa";
export var attoiconBreak = "main-module--attoicon-break--nzE7M";
export var attoiconNightShift = "main-module--attoicon-night-shift--E+D-y";
export var attoiconOvertime = "main-module--attoicon-overtime--B1iyW";
export var attoiconTimeTracking1 = "main-module--attoicon-time-tracking1--WjAkh";
export var attoiconTimeTracking = "main-module--attoicon-time-tracking--kdWl0";
export var attoiconLocationHistory = "main-module--attoicon-location-history--AUm8W";
export var attoiconPrivacy = "main-module--attoicon-privacy--ggly9";
export var attoiconTimeEntryHistory = "main-module--attoicon-time-entry-history--J14t2";
export var attoiconCustomize = "main-module--attoicon-customize--Due8H";
export var attoiconRoundedClock = "main-module--attoicon-rounded-clock--Lez4g";
export var attoiconTimeOff = "main-module--attoicon-time-off--lwEij";
export var attoiconNamedLocations = "main-module--attoicon-named-locations--HbZuo";
export var attoiconSandClock = "main-module--attoicon-sand-clock--edkRK";
export var attoiconBattery = "main-module--attoicon-battery--E2R4J";
export var attoiconSupport = "main-module--attoicon-support--+uJ5f";
export var attoiconHelpSupport = "main-module--attoicon-help-support--cR6z0";
export var attoiconWebTt = "main-module--attoicon-web-tt--Les1h";
export var attoiconArchive = "main-module--attoicon-archive--oVHNi";
export var attoiconEmail = "main-module--attoicon-email---12gq";
export var attoiconKiosk = "main-module--attoicon-kiosk--oH-m4";
export var attoiconShare = "main-module--attoicon-share--1Hqai";
export var attoiconCrew = "main-module--attoicon-crew--OIU1E";
export var attoiconTeamActivity = "main-module--attoicon-team-activity--19RVx";
export var attoiconTeam = "main-module--attoicon-team--BwnQg";
export var attoiconWages = "main-module--attoicon-wages--cmMD7";
export var attoiconNotification = "main-module--attoicon-notification--eOVHy";
export var attoiconAvatar = "main-module--attoicon-avatar--CdtMC";
export var attoiconViewMap = "main-module--attoicon-view-map--CSlPx";
export var attoiconMovementTracking = "main-module--attoicon-movement-tracking--wglIb";
export var attoiconWageEstimates = "main-module--attoicon-wage-estimates--5Jiwe";
export var attoiconWageEstimatesBold = "main-module--attoicon-wage-estimates-bold--I98dX";
export var attoiconClose = "main-module--attoicon-close--EeXg6";
export var attoiconPlus = "main-module--attoicon-plus--hKLzq";
export var attoiconMinus = "main-module--attoicon-minus--df16v";
export var attoiconTick = "main-module--attoicon-tick--7glYY";
export var attoiconArrowLeft = "main-module--attoicon-arrow-left--SK5nw";
export var attoiconArrowRight = "main-module--attoicon-arrow-right--+oipg";
export var attoiconArrowDown = "main-module--attoicon-arrow-down--qokBg";
export var attoiconArrowUp = "main-module--attoicon-arrow-up--yvXY8";
export var attoiconPlay = "main-module--attoicon-play--zXLcd";
export var attoiconLongArrow = "main-module--attoicon-long-arrow--+fyBM";
export var container = "main-module--container--YtIZ1";
export var industryPadding = "main-module--industryPadding--tokmR";
export var noPadding = "main-module--noPadding--pnuK0";
export var background = "main-module--background--HOQie";
export var oldVsNewGradient = "main-module--oldVsNewGradient---XP8o";
export var imageWrapper = "main-module--imageWrapper--8xOpK";
export var imageFormWrapper = "main-module--imageFormWrapper--MjReF";
export var gatsbyImageWrapper = "main-module--gatsby-image-wrapper--gqk59";
export var formRotated = "main-module--formRotated--WN40k";
export var learnMoreContainer = "main-module--learnMoreContainer--tmJhC";