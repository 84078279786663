import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useIntl, navigate } from 'gatsby-plugin-react-intl';
import useScroll from 'react-use-scroll';
import { Helmet } from 'react-helmet';
import Button from '@components/atoms/button';
import Modal from '@components/molecules/modal';
import Accordion from '@components/organisms/accordion';
import { StaticImage } from 'gatsby-plugin-image';

import ProductIcon from '@images/menu-schedule.svg';
import TimeTrackingIcon from '@images/time-tracking.svg';
import TeamActivityIcon from '@images/team-activity.svg';

import { apiUrl } from '@helpers';

import {
  headerWrapper,
  onScrollStyle,
  container,
  leftNav,
  headerItems,
  menuLinks,
  login,
  rightNavButtons,
  menu,
  menuSection,
  contentPadding,
  leftItems,
  notMobile,
  mobile,
  menuItem,
  activeMenuItem,
  resourcesMenu,
  menuIcon,
  menuItemDesc,
  pricingStyle,
  menuTrigger,
  activeButton,
  tiles1,
  tiles2,
  tiles3,
  collapsibleMenu,
  mobileButtons,
} from './header.module.scss';

import './collapsible.scss';

const HeaderComponent = ({ headerStyle }) => {
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const [shadow, setShadow] = useState(false);

  const [overMenu, setOverMenu] = useState(false);
  const [aboveLinks, setAbove] = useState(false);

  const [activeItem, setActiveItem] = useState(null);

  const [showDialog, setShowDialog] = useState(false);
  const [values, setValues] = useState(null);
  const openModal = () => setShowDialog(true);
  const closeModal = () => setShowDialog(false);

  const scroll = useScroll();
  const Intl = useIntl();

  const listentoScroll = () => (scroll === 0 ? setShadow(false) : setShadow(true));

  useEffect(() => {
    listentoScroll();
  }, [scroll]);

  const productSection = () => (
    <div className={menuSection}>
      <div className={`${leftItems} ${contentPadding}`}>
        <div>
          <Link className={`${menuItem}`} to="/product/scheduling" activeClassName={activeMenuItem}>
            <div className={menuIcon}>
              <ProductIcon />
            </div>
            <div className={menuItemDesc}>
              <div className={notMobile}>
                <h6>{Intl.formatMessage({ id: 'header.menu.schedulingLabel' })}</h6>
                <p>{Intl.formatMessage({ id: 'header.menu.schedulingDesc' })}</p>
              </div>
              <div className={mobile}>
                <h6>Scheduling</h6>
                <p>Build your schedule, assign shifts and keep your staff posted</p>
              </div>
            </div>
          </Link>
          <Link className={menuItem} activeClassName={activeMenuItem} to="/product/time-tracking">
            <div className={menuIcon}>
              <TimeTrackingIcon />
            </div>
            <div className={menuItemDesc}>
              <h6>{Intl.formatMessage({ id: 'header.menu.timesheetsTrackingLabel' })}</h6>
              <p>{Intl.formatMessage({ id: 'header.menu.timesheetsTrackingDesc' })}</p>
            </div>
          </Link>
          <Link className={menuItem} activeClassName={activeMenuItem} to="/product/team-management">
            <div className={menuIcon}>
              <TeamActivityIcon />
            </div>
            <div className={menuItemDesc}>
              <h6>{Intl.formatMessage({ id: 'header.menu.teamManagementLabel' })}</h6>
              <p>{Intl.formatMessage({ id: 'header.menu.teamManagementDesc' })}</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );

  const viewMenuSections = (val) => {
    switch (val) {
      case 'product':
        return productSection();
      default:
        return null;
    }
  };

  const menuClasses = (val) => {
    let menuClass = menu;
    if (val === 'resources') {
      menuClass += ` ${resourcesMenu}`;
    }
    return menuClass;
  };

  const toggleMenu = () => {
    setOpenMobile(!openMobile);
  };

  const overMenuLinks = () => {
    setOverMenu(true);
    setAbove(false);
  };

  const showMenu = (val) => {
    setOpen(true);
    setActiveItem(val);
  };

  const menuStateConfig = () => {
    if (!overMenu && !aboveLinks) {
      setOpen(false);
    }
  };

  const menuItemsOne = [
    {
      title: Intl.formatMessage({ id: 'header.menu.productLabel' }),
      url: false,
      description: productSection(),
    },
    ,
    /*  {
      title: Intl.formatMessage({ id: 'header.menu.pricingLabel' }),
      description: '',
      url: '/pricing',
    } */ {
      title: Intl.formatMessage({ id: 'header.menu.contactLabel' }),
      description: '',
      url: '/contact',
    },
  ];

  const toggleDeleteInvite = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: data.email }),
    };
    fetch(`${apiUrl}/delete-invite`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setValues(res);
        openModal();
      });
  };

  const formSuccessState = (val) => {
    if (val) {
      closeModal();
      setValues(val);
    } else {
      toggleDeleteInvite(val);
    }
  };

  useEffect(() => {
    if (values) {
      setTimeout(() => {
        setShowDialog(true);
      }, 1500);
    }
  }, [values]);

  useEffect(() => {
    menuStateConfig();
  }, [overMenu, aboveLinks]);

  return (
    <header
      className={`${headerWrapper} ${headerStyle && pricingStyle} ${shadow && onScrollStyle}`}
    >
      <Modal
        close={closeModal}
        showDialog={showDialog}
        hasValues={values}
        setFormValues={(formValues) => formSuccessState(formValues)}
      />
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <div className={container}>
        <div className={headerItems}>
          <div className={leftNav}>
            <Link to="/">
              <StaticImage
                src="../../../images/logo@3x.png"
                alt="footer-logo"
                quality={98}
                width={124}
                height={22}
                placeholder="none"
              />
            </Link>
          </div>
          <div className={menuLinks} onMouseEnter={() => setAbove(true)}>
            <a
              href="javascript:void(0)"
              onClick={(e) => e.preventDefault()}
              activeClassName={activeMenuItem}
              onMouseEnter={() => showMenu('product')}
            >
              {Intl.formatMessage({ id: 'header.menu.productLabel' })}
            </a>
            {/* <Link
              to="/pricing"
              activeClassName={activeMenuItem}
              onMouseEnter={() => setOpen(false)}
            >
              {Intl.formatMessage({ id: 'header.menu.pricingLabel' })}
            </Link> */}
            <Link
              to="/contact"
              activeClassName={activeMenuItem}
              onMouseEnter={() => setOpen(false)}
            >
              {Intl.formatMessage({ id: 'header.menu.contactLabel' })}
            </Link>
          </div>
          <div className={rightNavButtons}>
            <a
              href="https://app.rosteroo.com/login"
              rel="noreferrer"
              target="_blank"
              className={login}
            >
              {Intl.formatMessage({ id: 'header.menu.login' })}
            </a>
            <Button
              onBtnClick={openModal}
              btnText={Intl.formatMessage({ id: 'header.menu.tryForFreeLabel' })}
              btnStyle="teal"
            />
            <button
              className={`${menuTrigger} ${openMobile && activeButton} `}
              type="button"
              aria-label="toggle-menu"
              onClick={() => toggleMenu()}
            >
              <i className={tiles1} />
              <i className={tiles2} />
              <i className={tiles3} />
            </button>
          </div>
        </div>
        {open && (
          <div
            className={menuClasses(activeItem)}
            onMouseEnter={() => overMenuLinks()}
            onMouseLeave={() => setOverMenu(false)}
          >
            {viewMenuSections(activeItem)}
          </div>
        )}
        {openMobile && (
          <div className={collapsibleMenu}>
            <Accordion
              items={menuItemsOne}
              isExpanded={null}
              arrowIcon
              noIconPadding
              mainMenuStyle
            />
            <div className={mobileButtons}>
              <Button
                btnStyle="gray"
                btnText={Intl.formatMessage({ id: 'header.menu.login' })}
                onBtnClick={() => navigate('https://app.rosteroo.com/login')}
              />
              <Button
                btnStyle="teal"
                btnText={Intl.formatMessage({ id: 'pages.miscellaneous.start14Days' })}
                onBtnClick={openModal}
              />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

HeaderComponent.propTypes = {
  headerStyle: PropTypes.string,
};

export default HeaderComponent;
